import { useEffect, useState } from "react";
import { Route, routerReplace } from "src/common/routing";
import { MFASetupRoutes } from "src/common/routing/routes";
import { useRouter } from "next/router";
import { useCurrentAuthFactor } from "src/components/routes/Settings/mfa/useCurrentAuthFactor";

interface Props {
  children: React.ReactElement;
}

export const RedirectFromMFAWrapper = ({ children }: Props) => {
  // This is so we do not see a flicker of the child component, if we redirect.
  const [showChildren, setShowChildren] = useState(false);
  const { loading, currentAuthId } = useCurrentAuthFactor();
  const router = useRouter();

  const isMFASetupFlowRoute = MFASetupRoutes.includes(router.pathname);

  useEffect(() => {
    if (!loading) {
      if (currentAuthId && isMFASetupFlowRoute) {
        routerReplace({ route: Route.Home });
        return;
      }
      setShowChildren(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isMFASetupFlowRoute]); // Should fetch once on component mount

  if (showChildren) {
    return <>{children}</>;
  }

  return <></>;
};
