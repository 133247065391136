import { useIdentityCheck } from "./useIdentityCheck";

interface Props {
  children: React.ReactNode;
  enforceIdentityCheck?: boolean;
}

export const IdentityCheckWrapper = ({
  enforceIdentityCheck,
  children,
}: Props) => {
  const { enforcingIdentityCheck } = useIdentityCheck(enforceIdentityCheck);

  if (enforcingIdentityCheck) {
    return null;
  }
  return <>{children}</>;
};
