import { Box, AlignItems, JustifyContent } from "@gocardless/flux-react";
import { Image } from "src/components/shared/Image";

type imageSizeType = "sm" | "lrg";

enum ImageSize {
  "sm" = "32px",
  "lrg" = "64px",
}

const SpinnerWithImage = ({
  imageSource = "",
  altText = "",
  imageSize = "sm",
}: {
  imageSource?: string;
  altText?: string;
  imageSize?: imageSizeType;
}) => (
  <Box
    gutterV={2}
    alignItems={AlignItems.Center}
    justifyContent={JustifyContent.Center}
    layout="flex"
    data-testid="contrast-spinner"
    spaceBelow={3}
    spaceAbove={3}
  >
    <Image
      name="ContrastSpinner"
      height={80}
      width={80}
      alt={altText}
      css={{ position: "absolute" }}
    />
    {imageSource && (
      <img
        src={imageSource}
        height={ImageSize[imageSize]}
        width={ImageSize[imageSize]}
        css={{ position: "absolute" }}
        alt={altText}
        data-testid="bank-logo"
      />
    )}
  </Box>
);

export default SpinnerWithImage;
