import {
  ResponsiveValue,
  CSSValue,
  SpaceScale,
  CSSRulesFunction,
  useTheme,
} from "@gocardless/flux-react";

import registry from "./registry";

export type ImageName = keyof typeof registry;

export interface ImageProps {
  name: ImageName;
  spacing?: ResponsiveValue<CSSValue<SpaceScale>>;
  width?: ResponsiveValue<number | string>;
  height?: ResponsiveValue<number | string>;
  alt?: string;
  viewBox?: string;
  preserveAspectRatio?: string;
}

const svgStyle: CSSRulesFunction<ImageProps> = (
  theme,
  { width = "100%", height = "100%" }
) => [
  { display: "block" },
  theme.responsive(width, (value) => ({
    width: value,
  })),
  theme.responsive(height, (value) => ({
    height: value,
  })),
];

const imageStyle: CSSRulesFunction<ImageProps> = (theme, props) => [
  {
    display: "inline-block",
  },
  theme.responsive(props.spacing, (s) => ({ margin: theme.spacing(s) })),
  theme.responsive(props.width, (value) => ({
    width: value,
  })),
  theme.responsive(props.height, (value) => ({
    height: value,
  })),
];

const Image: React.FC<ImageProps> = (props) => {
  const { theme } = useTheme();
  const { name, spacing, alt, viewBox, preserveAspectRatio, ...rest } = props;
  const SVG = registry[name];

  const svg = (
    <SVG
      css={svgStyle(theme, props)}
      {...(viewBox && { viewBox })}
      {...(preserveAspectRatio && { preserveAspectRatio })}
    />
  );
  return (
    <span css={imageStyle(theme, props)} aria-label={alt} aria-hidden {...rest}>
      {svg}
    </span>
  );
};

export default Image;
