import { useMultiEntityFeature } from "../hooks/useMultiEntityFeature";

export const MultiEntityRestriction: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { hasMultiEntityFeature } = useMultiEntityFeature();

  if (!hasMultiEntityFeature) return null;

  return <>{children}</>;
};
