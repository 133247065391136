import { LoadingPage } from "src/components/layout/LoadingPage";
import { Route, routerPush } from "src/common/routing";

import {
  isMFAEnforcementDataLoaded,
  useMFAEnforcementData,
} from "./useMFAEnforcementData";

interface Props {
  children: React.ReactNode;
  enforceMFA?: boolean;
}

export const EnforceMFAWrapper = ({
  enforceMFA: routeShouldEnforceMFA = true,
  children,
}: Props) => {
  const mfaData = useMFAEnforcementData();

  if (!isMFAEnforcementDataLoaded(mfaData)) {
    return <LoadingPage showSpinner={false} />;
  }

  const {
    data: { skipMFASetupScreen },
  } = mfaData;

  const redirectToMFASetup = routeShouldEnforceMFA && !skipMFASetupScreen;

  if (redirectToMFASetup) {
    routerPush({ route: Route.MFASetup });
    return <LoadingPage showSpinner={false} />;
  }

  return <>{children}</>;
};
