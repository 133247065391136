/* modified by ui-hub */
import loadable from "@loadable/component";

const registry = {
  ContrastSpinner: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/spinner-contrast.svg"))
        .ReactComponent
  ),
};

export default registry;
