import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import {
  Banner,
  BannerVariant,
  Box,
  ColorPreset,
  FontWeight,
  PlainButton,
  Separator,
  Space,
  Text,
  Visibility,
} from "@gocardless/flux-react";
import { ToTranslate } from "src/components/i18n";
import { useSwitchOrganisation } from "src/common/hooks/useSwitchOrganisation";

import { useOrgSwitcherData } from "../../Home/components/OrganisationSwitcher/hooks/useOrgSwitcherData";
import { useMultiEntitySessionStorage } from "../hooks/useMultiEntitySessionStorage";

export const OrganisationBanner: React.FC = () => {
  const { sessionStorage, setSessionStorage } = useMultiEntitySessionStorage();

  const [isBannerDismissed, setIsBannerDismissed] = useState<boolean>(
    () => sessionStorage.isBannerDismissed
  );

  const { loggedInOrganisation } = useOrgSwitcherData();

  const { id: loggedInOrganisationId, name: loggedInOrganisationName } =
    loggedInOrganisation ?? {};

  const { submit, viewingChildOrganisation } = useSwitchOrganisation({});

  useEffect(() => {
    if (!loggedInOrganisationId) return;

    if (sessionStorage.currentOrgId !== loggedInOrganisationId) {
      setIsBannerDismissed(false);

      setSessionStorage({
        currentOrgId: loggedInOrganisationId,
        ...(viewingChildOrganisation ? { isBannerDismissed: false } : {}),
      });
    }
  }, [
    loggedInOrganisationId,
    sessionStorage,
    setSessionStorage,
    viewingChildOrganisation,
  ]);

  const handleClose = () => {
    setIsBannerDismissed(true);

    setSessionStorage({
      isBannerDismissed: true,
    });
  };

  if (!viewingChildOrganisation || isBannerDismissed) {
    return null;
  }

  return (
    <Banner
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose: handleClose,
      }}
      variant={BannerVariant.FullBleed}
    >
      <Box>
        <Text layout={["block", null, "inline-block"]}>
          <ToTranslate>
            You are now logged in as{" "}
            <Text weight={FontWeight.SemiBold}>{loggedInOrganisationName}</Text>
            .
          </ToTranslate>
        </Text>
        {sessionStorage.previousOrgId && (
          <>
            <Visibility visible={["none", null, "inline-block"]}>
              <Separator
                color={ColorPreset.BorderOnLight_03}
                direction="inline-vertical"
                spacing={0.5}
                thickness={1}
              />
            </Visibility>

            <Space layout={["block", null, "inline"]} v={[0.25, null, 0]} />
            <PlainButton
              onClick={() => submit(sessionStorage.previousOrgId)}
              textDecoration="underline"
            >
              <ToTranslate>Undo</ToTranslate>
            </PlainButton>
          </>
        )}
      </Box>
    </Banner>
  );
};
