import { useState } from "react";
import {
  AccessTokenCreateRequestBodyScope,
  UserResource,
  CreditorResource,
} from "@gocardless/api/dashboard/types";
import { getConfig } from "src/common/config";

import { loadZendeskScript } from "./client/zendesk";

export const useLoadZendesk = () => {
  const [isZendeskLoaded, setZendeskLoaded] = useState(false);

  // Function to check if the user meets the criteria
  const checkUserMeetsCriteria = (
    user: UserResource,
    creditor: CreditorResource
  ): boolean => {
    const supportedLocales = ["en-gb", "fr", "es", "de"]; // List of supported locales
    if (!user?.locale) {
      return false;
    }
    if (!creditor?.created_at) {
      return false;
    }

    const isSupportedLocale = supportedLocales.includes(
      user?.locale.toLowerCase()
    ); // Check if user's locale is supported
    const isPreActivated = !creditor.activated; // User should not be active (pre-activated)
    // Check if the user was created within the last 100 days
    const createdAt = new Date(creditor.created_at);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - createdAt.getTime();
    const daysSinceCreation = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days
    const ttl = daysSinceCreation <= 100;
    // The user meets the criteria if all conditions are true
    return isSupportedLocale && isPreActivated && ttl;
  };

  // Function to check if the user meets the criteria and cache the result
  const checkAndCacheUserMeetsCriteria = async (
    user: UserResource,
    creditor: CreditorResource
  ): Promise<boolean> => {
    // Ensure that user.id and user.email are defined before proceeding
    if (!user.id || !user.email) return false;
    const cacheKey = `zendesk_user_criteria_${user.id}`; // Use user's ID for the cache key
    const cachedData = localStorage.getItem(cacheKey);

    // Check if there's cached data and it's less than a day old
    if (cachedData) {
      const { result, timestamp } = JSON.parse(cachedData);
      const isLessThanADayOld =
        new Date().getTime() - timestamp < 1000 * 3600 * 24; // 24 hours
      if (isLessThanADayOld) {
        return result; // Return the cached result if it's still valid
      }
    }
    // Fetch Zendesk user data and check criteria
    if (!creditor) {
      return false; // Return false as criteria are not met
    }
    const meetsCriteria = checkUserMeetsCriteria(user, creditor);
    // Cache the result with a timestamp
    const dataToCache = {
      result: meetsCriteria,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(dataToCache));

    return meetsCriteria; // Return the result of the criteria check
  };

  // Modified loadZendesk function to include criteria checks
  const loadZendesk = async (
    user: UserResource | undefined,
    creditor: CreditorResource | undefined,
    scope?: AccessTokenCreateRequestBodyScope | undefined
  ) => {
    let zendeskAppId = getConfig().client.zendesk.appId;
    let jwt_secret = getConfig().client.zendesk.jwt_secret;
    let jwt_id = getConfig().client.zendesk.jwt_id;

    if (
      process.env.ZENDESK_KEY_ID &&
      process.env.ZENDESK_SECRET_KEY &&
      process.env.ZENDESK_APP_ID
    ) {
      jwt_secret = process.env.ZENDESK_SECRET_KEY;
      jwt_id = process.env.ZENDESK_KEY_ID;
      zendeskAppId = process.env.ZENDESK_APP_ID;
    }

    if (
      !window ||
      !user ||
      !creditor ||
      !zendeskAppId ||
      (user.scope || scope) !== AccessTokenCreateRequestBodyScope.Admin
    ) {
      return;
    }
    // Use the new function to check and cache the criteria result
    const meetsCriteria = await checkAndCacheUserMeetsCriteria(user, creditor);
    if (meetsCriteria) {
      setZendeskLoaded(true);
    }
    loadZendeskScript(zendeskAppId, user, { jwt_secret, jwt_id });
  };

  return {
    loadZendesk,
    isZendeskLoaded,
  };
};
