import {
  Box,
  P,
  PlainLink,
  JustifyContent,
  Separator,
  Tag,
  TagColor,
  TagVariant,
  TagSize,
  HoverEffect,
  Space,
  Color,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { isDev } from "src/common/hosts";

const STATUS_URL = "https://www.gocardless-status.com/";
//TIMES SHOULD BE IN UTC
const MAINTENANCE_START_TIME = "2022-07-23 09:00:00";
const MAINTENANCE_END_TIME = "2022-07-23 15:00:00";

const MaintenanceNotice = () => {
  const isBannerEnabled = !isDev;

  const isoDateTimeFormat = "YYYY-MM-DD HH:mm:ss Z";
  const currentTime = moment().format(isoDateTimeFormat);
  const currentTimeUTC = moment.utc(currentTime, isoDateTimeFormat);
  const startTimeUTC = moment.utc(MAINTENANCE_START_TIME, isoDateTimeFormat);
  const endTimeUTC = moment.utc(MAINTENANCE_END_TIME, isoDateTimeFormat);

  const isMaintenanceInProgress =
    !currentTimeUTC.isBefore(startTimeUTC) &&
    !currentTimeUTC.isAfter(endTimeUTC);

  return isBannerEnabled && isMaintenanceInProgress ? (
    <Box>
      <Box layout="flex" justifyContent={JustifyContent.Center}>
        <Box
          layout="flex"
          gutterV={1}
          gutterH={1}
          flexDirection={["column", null, null, "row"]}
        >
          <Tag
            variant={TagVariant.Solid}
            color={TagColor.Warning}
            size={TagSize.Md}
          >
            <Trans id="maintenance.notice.maintenance">MAINTENANCE</Trans>
          </Tag>
          <Space layout="inline" h={1} />
          <P>
            <Trans id="maintenance.notice.maintenance.message">
              We are currently performing essential maintenance to ensure the
              continued reliability of our service. You may experience degraded
              performance during the maintenance window.
            </Trans>
            &nbsp;
            <PlainLink
              href={STATUS_URL}
              target="_blank"
              rel="noopener noreferrer"
              layout="inline"
              textDecoration="underline"
              effect={HoverEffect.TextDecoration}
            >
              <Trans id="maintenance.notice.more-info">More Info</Trans>
            </PlainLink>
          </P>
        </Box>
      </Box>
      <Separator color={Color.Warning_300} />
    </Box>
  ) : null;
};

export default MaintenanceNotice;
