import {
  Banner,
  BannerStatus,
  BannerVariant,
  Box,
  ColorPreset,
  FontWeight,
  HoverEffect,
  P,
  PlainLink,
  Separator,
  TypePreset,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { isSandboxEnvironment } from "src/common/consts";
import { useSessionStorage } from "react-use";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import { LinkBuilder, Route } from "../routing";

export const SandboxBanner: React.FC = () => {
  const { i18n } = useLingui();
  const [isHidden, setIsHidden] = useSessionStorage(
    "hideSandboxBanner",
    "false"
  );
  const showSandboxBanner = isSandboxEnvironment && isHidden !== "true";

  return showSandboxBanner ? (
    <Box>
      <Banner
        variant={BannerVariant.FullBleed}
        leftAccessory={{
          type: BannerLeftAccessoryType.Status,
          status: BannerStatus.Info,
        }}
        closeAction={{
          onClose: () => {
            setIsHidden("true");
          },
          label: i18n._(t({ id: "close", message: "Close" })),
        }}
      >
        <P preset={TypePreset.Body_02}>
          <Trans id="sandbox-banner.youre-in-sandbox-mode">
            You&apos;re currently in sandbox mode.
          </Trans>{" "}
          <PlainLink
            href="https://manage.gocardless.com/sign-in"
            effect={HoverEffect.TextDecoration}
            textDecoration="underline"
            weight={FontWeight.SemiBold}
          >
            <Trans id="sandbox-banner.sign-in">Sign in</Trans>
          </PlainLink>{" "}
          <Trans id="sandbox-banner.or">or</Trans>{" "}
          <LinkBuilder route={Route.SignUp}>
            {(result) => (
              <PlainLink
                {...result}
                effect={HoverEffect.TextDecoration}
                textDecoration="underline"
                weight={FontWeight.SemiBold}
              >
                <Trans id="sandbox-banner.create-live-account">
                  create a live account
                </Trans>
              </PlainLink>
            )}
          </LinkBuilder>
          .
        </P>
      </Banner>
      <Separator color={ColorPreset.BorderOnLight_04} />
    </Box>
  ) : null;
};
