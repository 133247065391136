import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useEffect } from "react";
import { useLoadIntercom } from "src/technical-integrations/intercom/useLoadIntercom";

declare global {
  interface Window {
    Intercom: (action: string) => void | undefined;
    intercomSettings?: {
      app_id: string;
      name: string;
      email: string;
      user_hash: string;
      user_id: string;
      language_override: string;
      hide_default_launcher?: boolean;
    };
  }
}

interface IntercomProps {
  children: React.ReactNode;
}

const Intercom: React.FC<IntercomProps> = (props) => {
  const { data } = useUserShowSelf();
  const { loadIntercom } = useLoadIntercom();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadIntercom(data?.users), [data?.users?.id]);

  return <>{props.children}</>;
};

export default Intercom;
